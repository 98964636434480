<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/article-3.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            weight="regular"
            title="for the safe, perfect cleaning system"
          />

          <base-heading
            size="text-h2"
            title="WELCOME TO NAMKANG"
            weight="medium"
          />

          <base-body>
            (주)남강의 기업 이념,<br>
              보다 안전하고 완전한 세척환경을 생각합니다.<br>
              The philosophy of NamKang. To Provide Save and Perfect Cleaning system.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn
              v-on:click="goAbout"
            >
              About NAMKANG
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    methods: {
      goAbout: function () {
        console.log('goAbout')
        const vm = this
        return vm.$router.push('/About')
      },
    },

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
